<template>
  <div class="columns is-centered">
    <div class="column is-5">
      <div
        class="content-box"
        :class="{
          'frosted-background-static': $isSafari,
          'frosted-background': !$isSafari,
        }"
      >
        <div v-if="err !== null" class="notification is-danger">{{ err }}</div>

        <table v-if="mdpOk" class="table is-fullwidth">
          <tbody>
          <tr v-for="date in dates">
            <td>
              <router-link :to="{name: 'AdminListeParties', params: {dir: date}}">
                {{ date }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>

        <div v-else>
          <input
            type="text"
            id="sketchy_liste_parties_username"
            class="input is-small"
            v-model="mdp"
            placeholder="Mot de passe"
            @keyup.enter="validerMdp"
          />
          <button
            class="button is-small"
            @click="validerMdp"
          >Valider</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-box {
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.table {
  tbody {
    tr {
      td {
        font-size: 12px;
        padding: 0;

        > a {
          display: inline-block;
          width: 100%;
          height: 100%;
          padding: 3px 9px;

          cursor: pointer;

          &:hover {
            background-color: #f8f8f8;
          }
        }
      }


    }
  }
}
</style>

<script>
export default {
  name: 'ListePartiesDates',

  data() {
    return {
      mdp: '',
      mdpOk: false,

      err: null,

      dates: [],
    };
  },

  created() {
    this.mdp = this.$session._admin_liste_mdp;
    if (this.$session._admin_liste_mdp) {
      this.listeDates();
    }
  },

  methods: {
    validerMdp() {
      this.$session._admin_liste_mdp = null;
      this.listeDates();
    },

    listeDates() {
      this.err = null;
      this.$axios.get(this.$url_serveur + '/api/admin/bV9x2SZDFaIw4aE5a8h2obOIkapwzhEt65JyagNP/regen_export_list', {
        params: {
          p: this.mdp,
        }
      })
        .then((resp) => {
          this.err = null;
          this.dates = resp.data;
          this.mdpOk = true;
          this.$session._admin_liste_mdp = this.mdp;
        })
        .catch((err) => {
          this.err = err;
          this.mdpOk = false;
          this.$session._admin_liste_mdp = null;
        });
    },
  },
}
</script>